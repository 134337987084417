<template>
  <div class="contractdetail">
    <el-row :gutter="2">
      <el-col :span="24">
        <div class="grid-content">
          <span class="label">合同编号: </span>
          <span class="value">{{ contractDetail.contract_code }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">对方公司名称: </span>
          <span class="value">{{ contractDetail.yifang }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">施工地点: </span>
          <span class="value">{{ contractDetail.projectPlace }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">联系人: </span>
          <span class="value">{{ contractDetail.lianxiren }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">电话: </span>
          <span class="value">{{ contractDetail.contact_phone }}</span>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">我方公司名称: </span>
          <span class="value">{{ contractDetail.jiafang }}</span>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">负责人: </span>
          <span class="value">{{ contractDetail.fuzeren }}</span>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content">
          <span class="label">是否结单: </span>
          <el-tag size="mini" :type="!contractDetail.is_jiedan ? 'primary' : 'success'">{{ !contractDetail.is_jiedan ? '未结单' : '已结单' }}</el-tag>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="2">
      <el-col :span="6">
        <div class="grid-content">
          <span class="label">欠款金额: </span>
          <span class="value">{{ contractDetail.qiankuan_amount }}</span>
        </div>
      </el-col>

      <el-col :span="18">
        <div class="grid-content">
          <span class="label">未返还材料情况: </span>
          <span class="value">{{ contractDetail.notreturnmat }}</span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    contractDetail: Object,
    userType: Object
  }
}
</script>
